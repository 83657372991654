import metaConfig from '~/config/meta.json'

import useScope from '../useScope'

import type { Meta } from '~/config/types'

const useMeta = (): Meta[keyof Meta] => {
    const scope = useScope()
    const meta  = metaConfig[scope.id as keyof Meta]

    return meta
}

export default useMeta