{
    "barometern": {
        "address": {
            "name": "Barometern-OT",
            "postalAddress": "Västra Sjögatan 7",
            "zipcode": "392 32",
            "city": "Kalmar"
        },
        "contact": {
            "name": "Annonsavdelningen",
            "phone": "0480-591 77",
            "email": "rekrytering@barometern.se"
        },
        "customerService": {
            "phone": "0480-591 00"
        },
        "helpAndSupport": {
            "phone": "0480-59 100",
            "contactUsLink": "https://www.barometern.se/kundcenter/kontaktaoss/"
        }
    },
    "bt": {
        "address": {
            "name": "Borås Tidning",
            "postalAddress": "Allégatan 67",
            "zipcode": "503 37",
            "city": "Borås"
        },
        "contact": {
            "name": "Annonsavdelningen",
            "phone": "033-7000 800",
            "email": "annonssupport@bt.se"
        },
        "customerService": {
            "phone": "033-7000700"
        },
        "helpAndSupport": {
            "phone": "033-700 07 00",
            "contactUsLink": "https://www.bt.se/kundcenter/kontaktaoss/"
        }
    },
    "smp": {
        "address": {
            "name": "Smålandsposten",
            "postalAddress": "Linnégatan 2",
            "zipcode": "351 70",
            "city": "Växjö"
        },
        "contact": {
            "name": "Annonsavdelningen",
            "phone": "0470-77 04 40",
            "email": "kontakt.mediehuset@smp.se"
        },
        "customerService": {
            "phone": "0470-77 05 36"
        },
        "helpAndSupport": {
            "phone": "0470-77 05 00",
            "contactUsLink": "https://www.smp.se/kundcenter/kontaktaoss/"
        }
    },
    "ut": {
        "address": {
            "name": "Ulricehamns Tidning",
            "postalAddress": "Bogesundsgatan 7",
            "zipcode": "523 34",
            "city": "Ulricehamn"
        },
        "contact": {
            "name": "Annonsavdelningen",
            "phone": "0321-262 00",
            "email": "annons@ut.se"
        },
        "customerService": {
            "phone": "0321-262 00"
        },
        "helpAndSupport": {
            "phone": "0321-262 00",
            "contactUsLink": "https://www.ut.se/kundcenter/kontaktaoss/"
        }
    },
    "blt": {
        "address": {
            "name": "BLT",
            "postalAddress": "Ronnebygatan 26",
            "zipcode": "371 89",
            "city": "Karlskrona",
            "phone": "0455-77000"
        },
        "contact": {
            "name": "Annonsavdelningen",
            "phone": "0455-612200",
            "email": "annons@bltsydostran.se"
        },
        "customerService": {
            "phone": "0455-77000"
        },
        "helpAndSupport": {
            "phone": "0455-77 000",
            "contactUsLink": "https://www.blt.se/kundcenter/kontaktaoss/"
        }
    },
    "kristianstadsbladet": {
        "address": {
            "name": "Kristianstadsbladet",
            "postalAddress": "Hörnet Nya Boulevarden-Västra Vallgatan",
            "zipcode": "291 84",
            "city": "Kristianstad"
        },
        "contact": {
            "name": "Annonsavdelningen",
            "phone": "044-18 55 32",
            "email": "rekrytering@kristianstadsbladet.se"
        },
        "customerService": {
            "phone": "044-18 55 32"
        },
        "helpAndSupport": {
            "phone": "044-18 55 00",
            "contactUsLink": "https://www.kristianstadsbladet.se/kundcenter/kontaktaoss/"
        }
    },
    "ystadsallehanda": {
        "address": {
            "name": "Ystads Allehanda",
            "postalAddress": "Österportstorg 1",
            "zipcode": "271 41",
            "city": "Ystad"
        },
        "contact": {
            "name": "Annonsavdelningen",
            "phone": "0411-557 915",
            "email": "foretagsannons@ystadsallehanda.se"
        },
        "customerService": {
            "phone": "0411-55 79 15"
        },
        "helpAndSupport": {
            "phone": "0411-55 78 00",
            "contactUsLink": "https://www.ystadsallehanda.se/kundcenter/kontaktaoss/"
        }
    },
    "trelleborgsallehanda": {
        "address": {
            "name": "Trelleborgs Allehanda",
            "postalAddress": "Trelleborg C B - friisgatan 5A",
            "zipcode": "231 21",
            "city": "Trelleborg"
        },
        "contact": {
            "name": "Annonsavdelningen",
            "phone": "0410-545 70",
            "email": "foretagsannons@trelleborgsallehanda.se"
        },
        "customerService": {
            "phone": "0411-55 79 15"
        },
        "helpAndSupport": {
            "phone": "0410-545 00",
            "contactUsLink": "https://www.trelleborgsallehanda.se/kundcenter/kontaktaoss/"
        }
    },
    "nsk": {
        "address": {
            "name": "Norra Skåne",
            "postalAddress": "Östergatan 5",
            "zipcode": "281 81",
            "city": "Hässleholm"
        },
        "contact": {
            "name": "Annonsavdelningen",
            "phone": "044-18 55 32",
            "email": "rekrytering@kristianstadsbladet.se"
        },
        "customerService": {
            "phone": "044-18 55 32"
        },
        "helpAndSupport": {
            "phone": "0451-74 51 00",
            "contactUsLink": "https://www.nsk.se/kundcenter/kontaktaoss/"
        }
    },
    "sydostran": {
        "address": {
            "name": "Sydöstran",
            "postalAddress": "Ronnebygatan 26",
            "zipcode": "371 89",
            "city": "Karlskrona",
            "phone": "0455-33 46 00"
        },
        "contact": {
            "name": "Annonsavdelningen",
            "phone": "0455-612200",
            "email": "annons@bltsydostran.se"
        },
        "customerService": {
            "phone": "0470-77 05 36"
        },
        "helpAndSupport": {
            "phone": "0455-33 46 00",
            "contactUsLink": "https://www.sydostran.se/kundcenter/kontaktaoss/"
        }
    },
    "olandsbladet": {
        "address": {
            "name": "Ölandsbladet",
            "postalAddress": "Kyrkogatan 18",
            "zipcode": "387 31",
            "city": "Borgholm"
        },
        "contact": {
            "name": "Annonsavdelningen",
            "phone": "0485-417 00",
            "email": "annonser@olandsbladet.se"
        },
        "helpAndSupport": {
            "phone": "0485-417 00",
            "contactUsLink": "https://www.olandsbladet.se/kundcenter/kontaktaoss/"
        }
    },
    "meraosterlen": {
        "address": {
            "name": "Mera Österlen",
            "postalAddress": "Österportstorg 1",
            "zipcode": "271 41",
            "city": "Ystad"
        },
        "contact": {
            "name": "",
            "phone": "",
            "email": ""
        },
        "helpAndSupport": {
            "phone": "0411-55 78 00",
            "contactUsLink": "https://www.meraosterlen.se/kundcenter/kontaktaoss/"
        }
    },
    "vxonews": {
        "address": {
            "name": "VXOnews",
            "postalAddress": "STORGATAN 1",
            "zipcode": "352 33",
            "city": "Växjö"
        },
        "contact": {
            "name": "",
            "phone": "",
            "email": ""
        }
    },
    "kalmarposten": {
        "address": {
            "name": "Kalmarposten",
            "postalAddress": "Västra Sjögatan 7",
            "zipcode": "392 32",
            "city": "Kalmar"
        },
        "contact": {
            "name": "",
            "phone": "",
            "email": ""
        }
    }
}