import { useMemo } from 'react'
import { useMatches } from '@remix-run/react'

const useHasMatch = (id: string): boolean => {
    const matchingRoutes = useMatches()

    const route = useMemo(() => {
        return matchingRoutes.find((route) => route.id === id)
    }, [matchingRoutes, id])

    if (route) {
        return true
    } else {
        return false
    }
}

export default useHasMatch