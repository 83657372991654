        {
            "mainTitle": "Vanliga frågor",
            "advertising": {
                "subTitle": "Annonsering",
                "questions": [
                    {
                        "questionHeading": "Hur bokar jag en annons?",
                        "sections": [
                            {
                                "howTo": {
                                    "lineOne": "Som privatperson bokar du annonser via vår ",
                                    "lineOneContinued": ". Du börjar med att ange dina kontaktuppgifter, och väljer sedan vilken typ av annons du vill skapa.",
                                    "lineOneLink": "https://privat-gota.cargoselfservice.com/login",
                                    "lineOneLinkText": "annonsstudio",
                                    "lineTwo": "Du kan också få hjälp via telefon att boka din annons mot en extra kostnad (via Swish). ",
                                    "lineTwoContinued": "eller",
                                    "lineTwoEnd": " så hjälper vi dig.",
                                    "lineTwoPhone": "0101775700",
                                    "lineTwoPhoneText": "Ring 010-177 57 00",
                                    "lineTwoLink": "kundcenter@gotamedia.se",
                                    "lineTwoLinkText": " skicka ett mail"
                                }
                            }
                        ]
                    },
                    {
                        "questionHeading": "Vilken typ av annons kan jag boka?",
                        "sections": [
                            {
                                "sectionHeading": "Familjeannonser",
                                "listContent": [
                                    "Bröllop",
                                    "Bröllopsjubileum och förlovning",
                                    "Nyfödda och adoption",
                                    "Dagens ros (redaktionellt på Ystads allehanda och Ölandsbladet)",
                                    "Hälsningar och säsongshälsningar",
                                    "Namnbyte",
                                    "Uppvaktning och uppvaktning undanbedes",
                                    "Gratulationer"
                                ]
                            },
                            {
                                "sectionHeading": "Köp och sälj",
                                "listContent": [
                                    "Bostad",
                                    "Motor",
                                    "Prylar",
                                    "Djur",
                                    "Förlorat",
                                    "Upphittat"
                                ]
                            },
                            {
                                "sectionHeading": "Annonsstorlek och prissättning",
                                "textContent": "Vi har ett maxtak på annonsernas storlek. Detta varierar beroende på vilken typ av annons man bokar."
                            },
                            {
                                "sectionHeading": "Storlekar (mm):",
                                "listContent": [
                                    "Bröllop och bröllopsjubileum - max höjd 88 mm.",
                                    "Köp & sälj",
                                    [
                                        "utan bild 24 mm hög (rubrik + 6 rader text) ",
                                        "med bild max 62 mm (6 rader text)."
                                    ],
                                    "Firarannons med bild max 80 mm."
                                ]
                            },
                            {
                                "textContent": "Annonserna har fasta priser och man kan inte överstiga dessa storlekar."
                            }
                        ]
                    },
                    {
                        "questionHeading": "Vad behöver finnas med i min annons?",
                        "sections": [
                            {
                                "sectionHeading": "Annonsstorlek och prissättning",
                                "textContent": "Vi har ett maxtak på annonsernas storlek. Detta varierar beroende på vilken typ av annons man bokar."
                            },
                            {
                                "sectionHeading": "Storlekar (mm):",
                                "listContent": [
                                    "Bröllop och bröllopsjubileum - max höjd 88 mm.",
                                    "Köp & sälj",
                                    [
                                        "utan bild 24 mm hög (rubrik + 6 rader text)",
                                        "med bild max 62 mm (6 rader text)."
                                    ],
                                    "Firaannons med bild max 80 mm."
                                ]
                            },
                            {
                                "textContent": "Annonserna har fasta priser och man kan inte överstiga dessa storlekar."
                            }
                        ]
                    },
                    {
                        "questionHeading": "När har ni bokningsstopp?",
                        "sections": [
                            {
                                "textContent": "Om du vill annonsera i vår papperstidning gäller följande bokningsstopp på alla placeringar:"
                            },
                            {
                                "sectionHeading": "Morgontidningar",
                                "textContent": "Kl 14:00, två arbetsdagar innan publicering"
                            },
                            {
                                "sectionHeading": "Österlenmagasinet",
                                "textContent": "Torsdag kl. 14:00 för publicering nästkommande torsdag"
                            }
                        ]
                    },
                    {
                        "questionHeading": "Varför måste jag ange e-postadress i annonsstudion?",
                        "sections": [
                            {
                                "textContent": "Vi behöver din e-postadress då den är den unika informationen vid en bokning i annonsstudio."
                            },
                            {
                                "textContent": "Om du vill boka utan e-postadress kan du ringa oss för bokning över telefon. Då tillkommer en serviceavgift á 99 kronor (betalas via Swish). "
                            }
                        ]
                    }
                ]
            },
            "payment": {
                "subTitle": "Betalning",
                "questions": [
                    {
                        "questionHeading": "Vad kostar det att annonsera?",
                        "sections": [
                            {
                                "textContent": "Våra annonser har fasta priser från 49 kr. Priset för din annons beror på dess innehåll och om du bokar den själv eller med vår hjälp."
                            },
                            {
                                "sectionHeading": "Bokning i annonsstudion",
                                "textContent": "I annonsstudion ser du priset per annons direkt."
                            },
                            {
                                "sectionHeading": "Bokning med vår hjälp",
                                "textContent": "Grundpriset för annons är detsamma som i annonsstudion, med en tillkommande serviceavgift á 99 kr."
                            }
                        ]
                    },
                    {
                        "questionHeading": "Hur betalar jag min annons?",
                        "sections": [
                            {
                                "sectionHeading": "Bokning via annonsstudio",
                                "textContent": "Betalning sker med kort (VISA/Mastercard) eller Swish."
                            },
                            {
                                "sectionHeading": "Bokning via telefon",
                                "textContent": "Annons och tillkommande serviceavgift betalas med Swish."
                            }
                        ]
                    },
                    {
                        "questionHeading": "Kan jag få tillbaka mina pengar om jag ångrar min annons?",
                        "sections": [
                            {
                                "textContent": "Om din annons inte publicerats i tidning eller på sajt får du tillbaka dina pengar. Tänk på att många av våra annonskategorier publiceras direkt på sajten när vi granskat och godkänt din annons."
                            },
                            {
                                "textContent": "Om din annons publicerats får du inte tillbaka dina pengar. Vid köp av annonser via internet gäller distansavtalslagen. Observera att ångerrätt inte gäller, då varan på grund av sin beskaffenhet inte kan återlämnas."
                            }
                        ]
                    }
                ]
            },
            "images": {
                "subTitle": "Bilder",
                "questions": [
                    {
                        "questionHeading": "Varför kan jag inte ladda upp min bild?",
                        "sections": [
                            {
                                "textContent": "Kontrollera att bilden har rätt format och storlek. Om du ändå har problem med att ladda upp din bild, kontrollera din internetuppkoppling och testa i annan webbläsare. "
                            },
                            {
                                "textContent": "Tänk på att stora bilder kanske inte kan laddas via din mobila uppkoppling."
                            }
                        ]
                    },
                    {
                        "questionHeading": "Vad ska jag tänka på när jag väljer bild till min annons?",
                        "sections": [
                            {
                                "sectionHeading": "Detta gäller för bild på sajt och i tidning",
                                "listContent": [
                                    "En bild som från början är liten i storlek och har låg upplösning, får dålig kvalitet när den dras upp i storlek.",
                                    "Text i bilden är inte tillåtet.",
                                    "Bilden bör ha bra ljus. En bild som är mörk från början, blir ännu mörkare i tryck. En bild som är suddig från början blir ännu suddigare i tryck."
                                ]
                            },
                            {
                                "textContent": "Du ansvarar själv för att bilden håller en god, tryckbar kvalitet. Om bilden har god kvalitet men blir dålig i tryck erbjuder vi en ny annons som kompensation."
                            },
                            {
                                "textContent": "Bilderna är skyddade enligt upphovsrättslagen. Fotograf måste anges om det är en yrkesfotograf som tagit din bild. Bilden ska då också godkännas för publicering av fotograf."
                            }
                        ]
                    }
                ]
            },
            "other": {
                "subTitle": "Övrigt",
                "questions": [
                    {
                        "questionHeading": "Vad gör jag om jag är missnöjd med min annons?",
                        "sections": [
                            {
                                "other": {
                                    "lineOne": "Om du är missnöjd kan du reklamera din annons. ",
                                    "lineOneLink": "tel:0101775700",
                                    "lineOneLinkText": "Ring 010-177 57 00",
                                    "lineOneContinued": " eller ",
                                    "lineOneLinkTwo": "mailto:kundcenter@gotamedia.se",
                                    "lineOneLinkTwoText": "skicka ett mail ",
                                    "lineOneEnd": "så hjälper vi dig. ",
                                    "lineTwo": "Observera att vi behöver din reklamation senast sju dagar efter annonsens publicering."
                                }
                            }
                        ]
                    },
                    {
                        "questionHeading": "Regler och villkor",
                        "sections": [
                            {
                                "textContentLink": "https://api-gota.cargoselfservice.com/uploads/logos/Bokningsvillkor.pdf",
                                "textContentLinkTitle": "Annonsvillkor Gota Media Cargo Selfservice"
                            }
                        ]
                    }
                ]
            },
            "secondMainTitle": "Hjälp och support",
            "helpAndSupport": {
                "text": "Vi hjälper dig gärna med din annons. För snabbast hjälp, använd vårt formulär.",
                "buttonFormText": "Kontakta oss via formulär",
                "buttonFormLink": "",
                "buttonPhoneText": "010-177 57 00",
                "buttonPhoneLink": "tel:0101775700"
            },
            "chat": {
                "generalScripts": "https://kc-chat.gotamedia.se",
                "toolbox": "https://chat.tele2vaxel.se/CTGotaMedia/Chat/Toolbox",
                "extra": "https://kc-chat.gotamedia.se/Chat/scripts/extra.js"
            }
        }